@use '../abstracts' as *;

.modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(black, 0.95);
  z-index: 999;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: -webkit-min-content;
    height: -moz-min-content;
    height: min-content;
    width: 80%;
    padding: 40px 20px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: $dark-400;
    }
    &::-webkit-scrollbar-thumb {
      background-color: $grey-600;
      border: 2px solid $dark-400;
    }

    img {
      object-fit: contain;
      height: 100%;
      width: 100%;
      margin-bottom: 40px;
    }
    .img-title {
      margin-top: 24px;
      color: $light;
    }
  }

  .close-btn {
    position: fixed;
    top: 32px;
    right: 64px;
    color: $light;
    font-size: 32px;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;

    &:hover {
      transition: all 0.2s;
      color: $primary;
    }
  }
}

.grid {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 64px;
  justify-content: center;
  width: fit-content;
  margin: auto;
  padding: 0 2rem;
  @include portfolio-item($width: 400px, $height: 400px);
}

.masonry {
  display: grid;
  grid-template-columns: repeat(6, minmax(auto-fit, 250px));
  grid-template-rows: repeat(4, 250px);
  grid-gap: 20px;
  justify-content: center;
  max-width: 85%;
  margin: 20px auto;

  .portfolio-item {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .preview-img {
      position: relative;
      width: 100%;
      height: 100%;
      .thumbnail {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      .link-btn {
        display: none;
      }
    }
    .project-description {
      opacity: 0;
      display: flex;
      align-items: center;
      position: absolute;
      height: 30px;
      width: 100%;
      bottom: 0px;
      padding: 0 12px;
      background-color: rgba($dark, 0.8);
    }

    &:hover {
      & > * {
        transition: all 0.3s ease;
      }

      &::after {
        @include triangle-highlight-right(30px);
      }

      .preview-img {
        transform: scale(1.05);
      }
      .project-description {
        opacity: 100;
      }
    }
  }

  .portfolio-item:nth-child(1) {
    grid-column: span 2;
    grid-row: span 2;
  }
  .portfolio-item:nth-child(2) {
    grid-column: 3 / span 2;
    grid-row: 1 / span 1;
  }
  .portfolio-item:nth-child(3) {
    grid-column: 3 / span 1;
    grid-row: 2 / span 1;
  }
  .portfolio-item:nth-child(4) {
    grid-column: 4 / span 2;
    grid-row: 2 / span 1;
  }
  .portfolio-item:nth-child(5) {
    grid-column: 6 / span 1;
    grid-row: 1 / span 2;
  }
  .portfolio-item:nth-child(6) {
    grid-column: 5 / span 1;
    grid-row: 1 / span 1;
  }
  .portfolio-item:nth-child(7) {
    grid-column: 1 / span 4;
    grid-row: 3 / span 1;
  }
  .portfolio-item:nth-child(8) {
    grid-column: 5 / span 2;
    grid-row: 3 / span 1;
  }
  .portfolio-item:nth-child(9) {
    grid-column: 1 / span 1;
    grid-row: 4 / span 1;
  }
  .portfolio-item:nth-child(10) {
    grid-column: 4 / span 3;
    grid-row: 4 / span 1;
  }
  .portfolio-item:nth-child(11) {
    grid-column: 2 / span 2;
    grid-row: 4 / span 1;
  }
}

@media screen and (max-width: $xl) {
  .modal {
    &-content {
      width: 100%;
    }
  }
  .grid {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 48px;
    @include portfolio-item($width: 100%, $height: 100%, $mobile: true);
  }
  .masonry {
    grid-template-columns: repeat(6, minmax(auto-fit, 220px));
    grid-template-rows: repeat(4, 220px);
    grid-gap: 10px;
    max-width: 90%;
    .portfolio-item {
      &:hover {
        & > * {
          transition: all 0.3s ease;
        }

        &::after {
          display: none;
        }

        .preview-img {
          transform: scale(1.05);
        }
        .project-description {
          opacity: 0;
        }
      }
    }
  }

  @media screen and (max-width: $lg) {
    .masonry {
      grid-template-columns: repeat(7, minmax(auto-fit, 220px));
      grid-template-rows: repeat(6, 220px);
      grid-gap: 6px;
      max-width: 95%;
      .portfolio-item:nth-child(1) {
        grid-column: span 3;
        grid-row: span 2;
      }
      .portfolio-item:nth-child(2) {
        grid-column: 4 / span 2;
        grid-row: 1 / span 1;
      }
      .portfolio-item:nth-child(3) {
        grid-column: 6 / span 2;
        grid-row: 5 / span 1;
      }
      .portfolio-item:nth-child(4) {
        grid-column: 3 / span 3;
        grid-row: 5 / span 1;
      }
      .portfolio-item:nth-child(5) {
        grid-column: 6 / span 2;
        grid-row: 1 / span 2;
      }
      .portfolio-item:nth-child(6) {
        grid-column: 4 / span 2;
        grid-row: 2 / span 1;
      }
      .portfolio-item:nth-child(7) {
        grid-column: 1 / span 7;
        grid-row: 4 / span 1;
      }
      .portfolio-item:nth-child(8) {
        grid-column: 1 / span 4;
        grid-row: 3 / span 1;
      }
      .portfolio-item:nth-child(9) {
        grid-column: 1 / span 2;
        grid-row: 5 / span 1;
      }
      .portfolio-item:nth-child(10) {
        grid-column: 1 / span 7;
        grid-row: 6 / span 1;
      }
      .portfolio-item:nth-child(11) {
        grid-column: 5 / span 3;
        grid-row: 3 / span 1;
      }
    }
  }

  @media screen and (max-width: $sm) {
    .grid {
      grid-template-columns: repeat(1, 1fr);
      grid-gap: 48px;
    }
    .masonry {
      grid-template-columns: repeat(4, minmax(auto-fit, 200px));
      grid-template-rows: repeat(auto-fit, 200px);
      grid-gap: 6px;
      max-width: 95%;
      height: fit-content !important;
      .portfolio-item:nth-child(1) {
        grid-column: span 2;
        grid-row: span 2;
      }
      .portfolio-item:nth-child(2) {
        grid-column: 3 / span 2;
        grid-row: 1 / span 1;
      }
      .portfolio-item:nth-child(3) {
        grid-column: 1 / span 2;
        grid-row: 3 / span 1;
      }
      .portfolio-item:nth-child(4) {
        grid-column: 1 / span 4;
        grid-row: 7 / span 1;
      }
      .portfolio-item:nth-child(5) {
        grid-column: 3 / span 2;
        grid-row: 2 / span 2;
      }
      .portfolio-item:nth-child(6) {
        display: none;
        grid-column: 3 / span 2;
        grid-row: 2 / span 1;
      }
      .portfolio-item:nth-child(7) {
        grid-column: 1 / span 4;
        grid-row: 4 / span 1;
      }
      .portfolio-item:nth-child(8) {
        grid-column: 1 / span 4;
        grid-row: 8 / span 1;
      }
      .portfolio-item:nth-child(9) {
        grid-column: 3 / span 2;
        grid-row: 5 / span 1;
      }
      .portfolio-item:nth-child(10) {
        grid-column: 1 / span 4;
        grid-row: 6 / span 1;
      }
      .portfolio-item:nth-child(11) {
        // display: none;
        grid-column: 1 / span 2;
        grid-row: 5 / span 1;
      }
    }
  }
}
