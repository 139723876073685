/** @format */

@use '../abstracts' as *;

.feature {
  background-color: $grey-700;
  width: 50%;
  height: 200px;
  box-shadow: $shadow-light;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: -60px auto 0 auto;
  padding: 20px 40px;

  &-item {
    text-align: center;
    min-width: 24%;
    margin: 0 10px;
    &-icon {
      padding: 0;
      fill: $primary;
      width: 50px;
      height: 50px;
    }
    &-title {
      color: $primary;
      font-weight: 600;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
}

.dev-intro {
  min-height: 500px;

  &-text {
    padding: 0 40px;
  }
  &-img {
    float: right;
    margin-left: 40px;
    margin-top: -60px;
    width: 400px;
  }
}

.skillset {
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
  background-position-y: 96px;
  background-color: $grey-700;
  max-height: 400px;
  p {
    padding: 0 40px;
  }

  &-list {
    padding: 20px 30px;
    display: flex;
    flex-wrap: wrap;
    max-width: 60%;
    &-item {
      border: 1px solid $primary;
      border-radius: 4px;
      color: $primary;
      font-size: 18px;
      padding: 0 12px;
      margin: 8px;
      &:hover {
        background-color: $primary;
        color: $dark;
      }
    }
  }
}

.workflow {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
  box-shadow: inset 0 0 0 9999px rgba($dark-400, 0.9);
  padding-bottom: 100px;
  text-align: center;

  &-list {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 60px;
    justify-items: center;
    margin-top: 40px;

    .progress-bar {
      position: absolute;
      top: 70px;
      height: 82%;
      width: 12px;
      background-color: $grey-700;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      .point {
        clip-path: polygon(5% 25%, 50% 0%, 95% 25%, 95% 75%, 50% 100%, 5% 75%);
        background-color: $primary;
        height: 32px;
        width: 32px;
      }
    }

    &-item {
      position: relative;
      background-color: $grey-700;
      padding: 40px 60px;
      box-shadow: $shadow-light;
      width: 80%;
      .list-number {
        position: absolute;
        top: -20px;
        color: rgba($info, 0.5);
        font-weight: 800;
      }
      .list-title {
        color: $primary;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 16px;
      }
      .list-description {
        text-align: left;
      }
    }

    &-item:nth-child(even) {
      margin-bottom: 80px;
      .list-number {
        right: 20px;
      }
      .list-title {
        text-align: left;
      }
    }
    &-item:nth-child(odd) {
      margin-top: 80px;
      .list-number {
        left: 20px;
      }
      .list-title {
        text-align: right;
      }
      .list-description {
        padding-left: 20px;
      }
    }
  }
}

.web-project {
  background-repeat: no-repeat;
  background-position: bottom left;
  background-size: 70%;
  background-color: $grey-700;
  text-align: center;
  padding-bottom: 120px;

  &-list {
    display: grid;
    gap: 30px;
    justify-items: center;
    grid-template-columns: repeat(3, 1fr);

    .portfolio-item {
      width: 100%;
    }
    @include portfolio-item($width: 600px, $height: auto);
  }
}

@media screen and (max-width: $xl) {
  .feature {
    width: 70%;
    height: 150px;

    &-item {
      &-icon {
        width: 40px;
      }
      &-title {
        font-size: 18px;
      }
    }
  }

  .dev-intro {
    &-text {
      padding: 0 40px;
    }
    &-img {
      float: right;
      margin-left: 8px;
      margin-top: 0px;
      width: 300px;
    }
  }

  .skillset {
    &-list {
      max-width: 80%;
      &-item {
        font-size: 14px;
      }
    }
  }

  .workflow {
    &-list {
      grid-gap: 10px;

      .progress-bar {
        .point {
          height: 26px;
          width: 26px;
        }
      }

      &-item {
        padding: 40px 50px;
        width: 85%;
      }
    }
  }

  .web-project {
    &-list {
      grid-template-columns: repeat(2, 1fr);
      @include portfolio-item($width: 500px, $height: auto, $mobile: true);
    }
  }
}
@media screen and (max-width: $lg) {
  .feature {
    width: 75%;
    height: 120px;

    &-item {
      &-icon {
        width: 36px;
      }
      &-title {
        font-size: 14px;
      }
    }
  }

  .skillset {
    min-height: 420px !important;
    &-list {
      max-width: 100%;
      &-item {
        font-size: 14px;
      }
    }
  }

  .workflow {
    &-list {
      .progress-bar {
        .point {
          height: 24px;
          width: 24px;
        }
      }
      &-item {
        .list-title {
          font-size: 20px;
        }
      }
    }
  }

  .web-project {
    &-list {
      grid-template-columns: repeat(2, 1fr);
      @include portfolio-item($width: 350px, $height: auto, $mobile: true);
    }
  }
}
@media screen and (max-width: $sm) {
  .feature {
    width: 90%;
    height: 80px;
    padding: 10px;

    &-item {
      min-width: 12%;

      &-icon {
        width: 24px;
        margin-top: -10px;
      }
      &-title {
        margin-top: -10px;
        font-size: 10px;
      }
    }
  }

  .dev-intro {
    &-img {
      display: none;
    }
  }

  .skillset {
    background-position-x: 90px;
    background-position-y: 250px;
    min-height: 500px !important;

    &-list {
      &-item {
        padding: 0 8px;
        margin: 5px;
        font-size: 12px;
      }
    }
  }

  .workflow {
    &-list > * + * {
      margin-bottom: 20px !important;
    }
    &-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      grid-gap: 0;
      margin: 0;

      .progress-bar {
        display: none;
      }

      &-item {
        .list-title {
          font-size: 20px;
        }
      }

      &-item:nth-child(odd) {
        margin-top: 0px;
        .list-title {
          width: 80%;
          margin-left: auto;
          font-size: 20px;
        }
      }
      &-item:nth-child(even) {
        .list-title {
          width: 80%;
          margin-right: auto;
        }
      }
    }
  }

  .web-project {
    &-list {
      grid-template-columns: repeat(1, 1fr);
      @include portfolio-item($width: 350px, $height: auto, $mobile: true);
    }
  }
}
