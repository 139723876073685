@use '../abstracts' as *;

.section-expertise {
  text-align: center;
  margin-bottom: 60px;

  .card-list {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    gap: 2rem;

    &-item {
      background-color: $grey-700;
      width: 100%;
      height: max-content;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 2rem;
      box-shadow: $shadow-light;

      .card-icon {
        width: 50px;
        height: 50px;
        fill: $primary;
        margin-bottom: 20px;
      }

      .card-title {
        color: $primary;
        text-transform: uppercase;
        margin-bottom: 20px;
      }
    }
  }
}

.section-bio {
  margin-bottom: 60px;
  .section-right {
    background-position: bottom right !important;
    &::after {
      @include triangle-highlight-left(30px);
    }
  }
  .section-left {
    background-position: bottom left !important;
    &::after {
      @include triangle-highlight-right(30px);
    }
  }
  .bio-card {
    position: relative;
    display: flex;
    align-items: center;
    background-color: $grey-700;

    background-repeat: no-repeat;
    background-size: 40%;
    width: 80%;
    min-height: 450px;
    padding: 60px;
    box-shadow: $shadow-light;
    margin-bottom: 60px;

    &-title {
      position: relative;
      width: max-content;
      display: flex;
      align-self: flex-start;
      margin-top: -60px;
      margin-right: auto;
    }

    &:nth-child(even) {
      .bio-card-title {
        margin-left: auto;
        margin-right: -10px;
      }
    }

    &-content {
      width: 70%;
      padding: 0 2%;

      .scroll-list {
        max-height: 420px;
        margin: 40px 0;
        padding-right: 40px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: $dark-400;
        }
        &::-webkit-scrollbar-thumb {
          background-color: $grey-600;
          border: 2px solid $dark-400;
        }
      }

      .experience-list {
        &-item {
          font-size: 26px;
          font-weight: 700;
          color: $primary;
          margin-bottom: 50px;
          z-index: 100;
          list-style-type: disc;

          .company,
          .description {
            color: $grey;
            padding-left: 36px;
          }

          .company {
            font-size: 24px;
            font-weight: 600;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 12px 0;

            .period {
              font-size: 18px;
              font-weight: 500;
              border: 1px solid $grey;
              border-radius: 6px;
              padding: 0 12px;
            }
          }

          .description {
            font-size: 20px;
            font-weight: 400;
          }
        }
      }

      .skill-list {
        h5 {
          color: $primary;
          margin-bottom: 10px;
        }
        .web-dev {
          margin-bottom: 40px;
        }

        .software-icon-list {
          .software-icon {
            margin-right: 20px;
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }
}

.section-gallery {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 100px;
}

.section-left {
  margin-right: auto;
  padding-left: 200px;
}
.section-right {
  margin-left: auto;
}

.highlight-project {
  padding-bottom: 5rem;
}

.highlight-project-list {
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  gap: 120px !important;
  margin-top: 30px;
  margin-bottom: 60px;
}

@media screen and (max-width: $xl) {
  .section-bio {
    .section-right {
      flex-direction: column-reverse !important;
    }

    .bio-card {
      width: 90%;
      flex-direction: column;
      background-size: 100%;

      &-content {
        width: 100%;
        .experience-list {
          &-item {
            font-size: 24px;
            .company {
              font-size: 20px;
              .period {
                font-size: 16px;
              }
            }
            .description {
              font-size: 16px;
            }
          }
        }
        .skill-list {
          .software-icon-list {
            .software-icon {
              width: 48px;
              height: 48px;
            }
          }
        }
      }
    }
  }

  .scrolldown-arrow {
    height: 24px;
    width: 24px;
  }
  .highlight-project-list {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}

@media screen and (max-width: $lg) {
  .section-expertise {
    .card-list {
      &-item {
        height: 300px;

        .card-icon {
          width: 40px;
        }
        .card-title {
          font-size: 1rem;
        }
        .card-text {
          font-size: 0.9rem;
          line-height: 1.3;
        }
      }
    }
  }

  .section-bio {
    .bio-card {
      width: 90%;

      &-content {
        width: 100%;
        .experience-list {
          &-item {
            font-size: 20px !important;
            .company {
              font-size: 18px !important;
              .period {
                font-size: 12px !important;
              }
            }
            .description {
              font-size: 16px !important;
            }
          }
        }

        .skill-list {
          .software-icon-list {
            .software-icon {
              width: 36px;
              height: 36px;
            }
          }
        }
      }
    }
  }

  .section-gallery {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: $sm) {
  .section-expertise {
    .card-list {
      flex-direction: column;
      &-item {
        height: auto;
      }
    }
  }

  .section-bio {
    .bio-card {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;

      &-content {
        .experience-list {
          &-item {
            .company {
              flex-direction: column;
              align-items: flex-start;
              font-size: 16px !important;
              margin-top: 0;
              .period {
                font-size: 12px;
                margin-top: 6px;
              }
            }
            .description {
              line-height: 1.5;
            }
          }
        }

        .skill-list {
          .software-icon-list {
            display: flex;
            flex-wrap: wrap;
            .software-icon {
              width: 30px;
              height: 30px;
              margin-right: 10px;
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }

  .scrolldown-arrow {
    height: 16px;
    width: 16px;
  }
  .section-gallery {
    margin-bottom: 0px;
  }
}

@media screen and (max-width: $xs) {
  .section-expertise {
    .card-list {
      // &-item {
      //   width: 280px;
      // }
    }
  }
  .section-bio {
    .bio-card {
      &-content {
        .skill-list {
          .software-icon-list {
            .software-icon {
              width: 24px;
              height: 24px;
            }
          }
        }
      }
    }
  }

  .scrolldown-arrow {
    height: 16px;
    width: 16px;
  }
}
