@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

// COLOUR
$primary-400: #31e2c9;
$primary: #08c6ab;
$primary-600: #00a18b;
$primary-700: #007b6a;
$secondary: #315ae2;
$secondary-500: #438afd;
$info: #37465b;
$light: #e9eaeb;
$grey: #9a9a9a;
$grey-600: #757575;
$grey-700: #252525;
$dark-400: #171717;
$dark: #111111;

// BREAKPOINTS
$xl: 1200px;
$lg: 992px;
$md: 768px;
$sm: 576px;
$xs: 320px;

// FONTS
$text-font-title: 'Montserrat', sans-serif;
$text-font-body: 'Poppins', sans-serif;

// SHADOW
$shadow-light: 0px 12px 16px rgba($dark, 0.8);
