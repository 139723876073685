@use '../abstracts' as *;

.navbar {
  height: 100px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  margin-bottom: -100px;
  z-index: 3;

  &-brand {
    .logo {
      height: 42px;
    }
  }

  &-nav {
    display: flex;
    justify-content: center;
    align-items: center;

    .nav-list {
      margin: 0 20px;

      .nav-link {
        position: relative;
        text-transform: uppercase;
        font-weight: 600;
        padding: 0 12px;
        &:hover {
          &::before {
            @include triangle-highlight-left();
          }
        }
      }

      .active {
        &::before {
          @include triangle-highlight-left();
        }
      }
    }
  }

  @media screen and (max-width: $md) {
    display: none;
  }
}

.navbar-mobile {
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 5%;
  margin-bottom: -100px;
  z-index: 2;

  .default {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100px;
  }

  .navbar-nav {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;

    .nav-list {
      margin: 40px 0;
      padding: 0;
      display: none;
      .nav-link {
        margin: 0;
        display: none;
      }
    }
    .nav-list:last-child {
      margin-bottom: 60px;
    }
  }
  // HAMBURGER TOGGLE BUTTON
  .hamburger-btn {
    position: relative;
    border: none;
    background: none;
    outline: none;
    cursor: pointer;
    height: 36px;
    width: 36px;
    display: none;
    justify-content: center;
    align-items: center;

    .bar {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 3px;
      background-color: $light;
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: -10px;
        width: 100%;
        height: 3px;
        background-color: $light;
      }

      &::after {
        top: 10px;
      }

      @media screen and (max-width: $md) {
        width: 60%;
      }
    }

    &:hover {
      .bar,
      .bar::after,
      .bar::before {
        transition: all 0.2s;
        background-color: $primary;
      }
    }

    @media screen and (max-width: $md) {
      display: flex;
    }
  }

  .clicked {
    .bar {
      transform: rotate(45deg);
    }
    .bar::before,
    .bar::after {
      top: 0;
      transform: rotate(90deg);
    }
  }

  .mobile {
    transition: all 0.2s;
    border-top: 1px solid $primary;
    margin-top: 0;
    margin-bottom: 100px;
    .nav-list {
      display: block;
      margin-bottom: 20px;
      opacity: 1;
      .nav-link {
        display: block;
        font-size: 20px;
      }
    }
  }
}
