@use '../abstracts' as *;

.main-header {
  background-attachment: fixed;
  .gradient-title {
    @include gradient-text();
    display: inline-block; // Set inline-block to fix text gradient issue in Safari
    flex-direction: column;
    flex-wrap: wrap;
    width: 100%;
    font-size: 42px;
    margin-bottom: 1rem;
  }
}

.header {
  .gradient-title {
    @include gradient-text();
    display: inline-block;
  }
}
.header,
.main-header {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  box-shadow: inset 0 0 0 9999px rgba($dark, 0.5);

  .header-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .align-left {
      margin-right: auto;
      padding-left: 10%;
    }

    .subtitle {
      color: $light;
      font-weight: 500;
    }

    .header-intro {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &-text {
        width: 100%;
        padding: 0 2rem;
        animation: fadeInLeft;
        animation-duration: 1s;

        .greeting {
          color: $primary;
          text-transform: uppercase;
          position: relative;
          .fa-caret-right {
            margin-left: 4px;
          }
        }

        .contact-list {
          margin-bottom: 64px;
          padding-left: 0;
          .list-item {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .icon {
              font-size: 24px;
              color: $primary;
              margin-right: 24px;
            }
            a {
              font-size: 18px;
            }
          }
        }

        .primary-lg {
          margin-bottom: 0;
        }
      }

      &-image {
        position: relative;
        animation: fadeIn;
        animation-duration: 1s;
        width: 70%;
        height: max-content;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          -webkit-clip-path: polygon(
            25% 5%,
            75% 5%,
            100% 50%,
            75% 95%,
            25% 95%,
            0% 50%
          );
          clip-path: polygon(
            5% 25%,
            50% 0%,
            95% 25%,
            95% 75%,
            50% 100%,
            5% 75%
          );

          width: 80%;
          height: auto;
          margin-left: 4rem;

          @media screen and (max-width: $xl) {
            width: 50%;
            height: auto;
          }
        }

        &::before {
          position: absolute;
          content: ' ';
          clip-path: polygon(
            5% 25%,
            50% 0%,
            95% 25%,
            95% 75%,
            50% 100%,
            5% 75%
          );
          height: 100%;
          width: 80%;
          right: 0rem;
          top: 6rem;
          background-color: rgba($primary, 0.2);

          @media screen and (max-width: $xl) {
            display: none;
          }
        }
      }
    }

    .scrolldown-arrow {
      position: absolute;
      right: 0;
      bottom: 24px;
      left: 0;
      margin: auto;
      background: rgba($primary, 0.5);
      height: 40px;
      width: 40px;
      clip-path: polygon(50% 68%, 0 0, 100% 0);
      animation: bounce;
      animation-duration: 1.2s;
      animation-iteration-count: infinite;

      @media screen and (max-width: $xl) {
        height: 24px;
        width: 24px;
      }
    }
  }
}

.header {
  height: 600px;
}

.main-header {
  height: 100vh;
}

@media screen and (max-width: $xl) {
  .header {
    height: 400px;
    .gradient-title {
      font-size: 40px;
    }
  }
  .main-header {
    background-attachment: unset;
  }

  .header,
  .main-header {
    .header-container {
      .subtitle {
        font-size: 20px;
      }

      .header-intro {
        flex-direction: column-reverse;
        justify-content: center;

        &-text {
          .greeting {
            font-size: 18px;
            margin-top: 48px;
          }
          .my-name {
            font-size: 38px;
          }
          .contact-list {
            & > * > * {
              font-size: 18px !important;
            }
          }

          button:last-of-type {
            display: flex;
            margin-right: auto;
            margin-left: auto;
          }
        }
      }

      .scrolldown-arrow {
        height: 24px;
        width: 24px;
      }
    }
  }
}

@media screen and (max-width: $lg) {
  .header,
  .main-header {
    .header-container {
      .gradient-title {
        font-size: 42px;
      }

      .subtitle {
        font-size: 18px;
      }
      .header-intro {
        &-text {
          .greeting {
            margin-top: 10px;
          }
          .my-name {
            font-size: 32px;
          }
          .gradient-title {
            margin: auto;
          }
        }
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .header {
    height: 360px;
  }
  .header,
  .main-header {
    .header-container {
      text-align: center;
      .gradient-title {
        justify-content: center;
        padding: 0 20px;
        margin: 0;
      }

      .subtitle {
        text-align: center;
        padding: 0 20px;
        margin-top: 12px;
        font-size: 14px;
      }
      .header-intro {
        &-image {
          img {
            width: 50%;
            height: auto;
            margin: 0;
          }
          &::before {
            display: none;
          }
        }

        &-text {
          width: 100%;
          display: flex;
          flex-direction: column;
          animation: fadeInUp;
          animation-duration: 1s;

          .greeting {
            font-size: 18px;
            margin-top: 25px;
          }
          .my-name {
            font-size: 24px;
          }
          .gradient-title {
            font-size: 30px;
            justify-content: center;
          }
          .contact-list {
            display: flex;
            justify-content: center;
            margin-bottom: 10px;
            .list-item {
              margin-top: 20px;
              a {
                .icon {
                  margin: 0 16px;
                  font-size: 30px;
                }
                .text-link {
                  display: none;
                }
              }
            }
          }
        }
        &-text > :last-child {
          margin: 0 auto;
        }
      }

      .scrolldown-arrow {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $xs) {
  .header,
  .main-header {
    .header-container {
      .header-intro {
        &-image {
          img {
            width: 40%;
            height: auto;
            margin: 0;
          }
        }
        &-text {
          width: 100%;
          .greeting {
            font-size: 12px;
            margin-top: 10px;
          }
          .my-name {
            font-size: 20px;
          }
          .gradient-title {
            flex-direction: column;
            font-size: 24px;
          }
          .contact-list {
            display: flex;
            justify-content: center;
            margin-bottom: 30px;
            .list-item {
              margin-top: 0px;
              a {
                .icon {
                  margin: 0 16px;
                  font-size: 24px;
                }
                .text-link {
                  display: none;
                }
              }
            }
          }
        }
      }

      .scrolldown-arrow {
        height: 16px;
        width: 16px;
      }
    }
  }
}
