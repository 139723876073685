@use '../abstracts' as *;

.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .tab-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;

    &-item {
      color: $grey;
      font-weight: 600;
      text-transform: uppercase;
      padding: 10px 40px;
      cursor: pointer;

      &::after {
        @include hightlight-bar(0px);
      }

      &:hover {
        transition: all 0.2s ease;
        position: relative;
        color: $primary;
        &::after {
          @include hightlight-bar(40px);
        }
      }
    }

    &-active {
      position: relative;
      color: $primary;
      &::after {
        @include hightlight-bar(40px);
      }
    }
  }

  .tab-content {
    width: 100%;
  }
}

.content-hide {
  display: none;
}

.content-active {
  display: block;
}

@media screen and(max-width:$xl) {
  .tabs {
    .tab-list {
      margin-bottom: 40px;

      &-item {
        padding: 10px 16px;
      }
    }
  }
}
@media screen and(max-width:$lg) {
  .tabs {
    .tab-list {
      width: 70%;
    }
  }
}
@media screen and(max-width:$sm) {
  .tabs {
    .tab-list {
      width: 90%;

      &-item {
        font-size: 14px;
        padding: 6px 10px;
        margin-bottom: 10px;
      }
    }
  }
}
