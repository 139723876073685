@use '../abstracts' as *;

.primary {
  @include button($size: m);
  @media screen and (max-width: $xl) {
    @include button($size: sm);
  }
}
.primary-lg {
  @include button($size: lg);
  @media screen and (max-width: $lg) {
    @include button($size: m);
  }
  @media screen and (max-width: $sm) {
    @include button($size: sm);
  }
}

.back-to-top {
  cursor: pointer;
  opacity: 100;
  position: fixed;
  bottom: 60px;
  right: 1.5%;
  height: 58px;
  width: 58px;
  outline: none;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $primary;
  color: $dark;
  font-size: 28px;
  z-index: 5;
  transition: all 0.2s;

  &:hover {
    background-color: $primary-600;
  }

  @media screen and (max-width: $md) {
    height: 48px;
    width: 48px;
    font-size: 24px;
  }
  @media screen and (max-width: $sm) {
    bottom: 180px;
  }
}

.disabled {
  transition: all 0.5s ease-in-out;
  pointer-events: none;
  cursor: unset;
  opacity: 0;
}
