/** @format */

@use '../abstracts' as *;

html {
  box-sizing: border-box;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $dark-400;
  overflow-y: unset;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: $light;
  transition: all 0.2s;
  &:hover {
    color: $primary;
  }
}

.container {
  margin-left: auto;
  margin-right: auto;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;

  @media screen and (max-width: $xl) {
    padding: 20px;
  }
  @media screen and (max-width: $md) {
    padding: 0 !important;
  }
}
.section {
  padding: 40px 0;
}

::selection {
  background-color: $primary;
  color: $dark-400;
}
