@use '../abstracts' as *;

.section-title {
  color: $primary;
  font-family: $text-font-body;
  font-weight: 800;
  text-transform: uppercase;
  padding: 0 20px;
  &::before {
    content: attr(first-character);
    font-size: 120px;
    font-weight: 800;
    text-transform: uppercase;
    font-family: $text-font-title;
    color: rgba($info, 0.3);
    margin-right: -30px;
  }
}

@media screen and (max-width: $xl) {
  .section-title {
    &::before {
      font-size: 96px;
    }
  }
}

@media screen and (max-width: $lg) {
  .section-title {
    padding: 0 30px;
    font-size: 25px;
    &::before {
      font-size: 72px;
    }
  }
}
@media screen and (max-width: $xs) {
  .section-title {
    font-size: 22px;
    &::before {
      font-size: 50px;
    }
  }
}
