@use '../abstracts' as *;

body {
  font-size: 20px;
  font-family: $text-font-body;
  line-height: 1.7;
  color: $grey;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: $text-font-title;
  font-weight: 600;
  letter-spacing: -1px;
  color: $light;
  margin: 0;
  line-height: 1.5;
}

h1 {
  font-size: 96px;
}

h2 {
  font-size: 64px;
}

h3 {
  font-size: 42px;
}

h4 {
  font-size: 32px;
}

h5 {
  font-size: 24px;
}

@media screen and (max-width: $xl) {
  body {
    font-size: 16px;
    line-height: 2;
  }

  h3 {
    font-size: 36px;
  }
  h4 {
    font-size: 24px;
  }
  h5 {
    font-size: 18px;
  }
}
