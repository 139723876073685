@use 'variables' as *;

@mixin button($color: $primary, $textColor: $dark, $size: lg) {
  background-color: $primary;
  color: $dark;
  border: none;
  outline: none;
  font-weight: 600;
  font-family: $text-font-body;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    transition: all 0.3s ease;
    color: $dark;
    background-color: $primary-600;
  }

  @if $size == lg {
    padding: 12px 24px;
    font-size: 18px;
  } @else if $size == m {
    padding: 10px 18px;
    font-size: 16px;
  } @else if $size == sm {
    padding: 4px 14px;
    font-size: 14px;
  }
}

@mixin triangle-highlight-left($size: 10px) {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  clip-path: polygon(0 0, 0% 90%, 90% 0);
  height: $size;
  width: $size;
  background-color: $primary;
}

@mixin triangle-highlight-right($size: 10px) {
  content: '';
  position: absolute;
  right: 0;
  top: 0;
  clip-path: polygon(100% 0, 10% 0, 100% 100%);
  height: $size;
  width: $size;
  background-color: $primary;
}

@mixin hightlight-bar($width: 40px) {
  transition: all 0.2s ease;
  position: absolute;
  content: ' ';
  background-color: $primary;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 4px;
  width: $width;
}

@mixin gradient-text($leftColor: $primary-400, $rightColor: $secondary) {
  font-weight: 700;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: uppercase;
  background: $primary-400;
  // background: -webkit-gradient(
  //   linear,
  //   left top,
  //   right top,
  //   from($leftColor),
  //   to($rightColor)
  // );
  // background: linear-gradient(to right, $leftColor 0%, $rightColor 100%);
  // background: -o-linear-gradient(left, $leftColor 0%, $rightColor 100%);
  // background: -moz-linear-gradient(left, $leftColor 0%, $rightColor 100%);
  // background: -webkit-linear-gradient(
  //   to right,
  //   $leftColor 0%,
  //   $rightColor 100%
  // );
  // background-clip: text;
  // -webkit-background-clip: text;
  // -webkit-text-fill-color: transparent;

  @media screen and (max-width: $xl) {
    color: $secondary-500;
    background-clip: unset;
    background: none;
    -webkit-text-fill-color: unset;
  }

  color: $primary-400;
  @supports ((background-clip: text) or (-webkit-background-clip: text)) and
    ((-webkit-text-fill-color: transparent)) {
    background: linear-gradient(to right, $leftColor 0%, $rightColor 100%);
    background: -o-linear-gradient(left, $leftColor 0%, $rightColor 100%);
    background: -moz-linear-gradient(left, $leftColor 0%, $rightColor 100%);
    background: -webkit-linear-gradient(
      to right,
      $leftColor 0%,
      $rightColor 100%
    );
    background-size: cover;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@mixin portfolio-item($width: 400px, $height: 400px, $mobile: false) {
  .portfolio-item {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    max-width: $width;
    height: $height;
    .preview-img {
      position: relative;
      overflow: hidden;
      height: 360px;
      width: 100%;

      .thumbnail {
        object-fit: cover;
        object-position: top;
        width: 100%;
        height: 100%;
      }

      .link-btn {
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 70%;
        width: 100%;
        left: 50%;
        right: 50%;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
        background-color: rgba($dark, 0);
        z-index: 2;
        h5 {
          padding: 20px;
          font-weight: 500;
        }

        .see-btn {
          @include button($size: m);
        }

        & > * {
          display: none;
        }

        transition: all 0.1s ease;

        @media screen and(max-width:$lg) {
          .see-btn {
            @include button($size: sm);
          }
        }
      }

      @if $mobile {
        .link-btn {
          background-color: rgba($dark, 0.6);
          & > * {
            display: flex;
          }
        }
      }
    }

    .project-description {
      width: 100%;
      margin: 10px auto;
      font-size: 1rem;
    }

    .tech-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: 100%;
      font-size: 0.9rem;

      &-item {
        color: $primary;
        border: 1px solid $primary;
        border-radius: 6px;
        padding: 0 0.5rem;
        margin-right: 0.5rem;
        margin-bottom: 0.5rem;
      }
    }

    &:hover {
      .preview-img {
        .link-btn {
          background-color: rgba($dark, 0.8);
          & > * {
            display: inline;
          }
        }
      }
    }
  }
}
