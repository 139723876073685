@use '../abstracts' as *;

.skillset {
  min-height: 500px;
  .software-icon-list {
    padding-left: 40px;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    & > * + * {
      margin-left: 20px;
      width: 60px;
      height: 60px;
    }
  }
}

.portfolio-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  background-position: top center;
  box-shadow: inset 0 0 0 9999px rgba($dark, 0.8);
  text-align: center;
  padding-bottom: 100px;

  .video-container {
    width: 70%;
    margin: 0 auto;
    .iframe-wrapper {
      position: relative;
      overflow: hidden;
      padding-bottom: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */
      .video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media screen and (max-width: $sm) {
  .skillset {
    .software-icon-list {
      padding: 0px 40px 0px 40px;

      & > * + * {
        margin-left: 0;
      }

      & > * {
        width: 34px;
        height: 34px;
        margin-right: 8px;
        margin-bottom: 8px;
      }
    }
  }
}
