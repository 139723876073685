/** @format */

@use '../abstracts' as *;

footer {
	height: 300px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	border-top: 1px solid $primary;
	box-shadow: inset 0 0 0 999999px rgba($dark, 0.5);
	z-index: 2;

	.container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		height: 100%;

		.title {
			text-align: center;
			h5 {
				display: inline-block;
				position: relative;
				color: $grey;
				text-align: center;
				text-transform: uppercase;
				font-size: 18px;
				font-weight: 500;
				margin-bottom: 40px;
				padding: 0 16px;

				&::before {
					@include triangle-highlight-left();
				}
			}
		}

		.link-list {
			display: flex;
			justify-content: center;

			a {
				display: flex;
				width: 64px;
				svg {
					fill: $primary;
					height: 32px;
				}
			}
		}
	}
	.copyright {
		height: 48px;
		width: 100%;
		background-color: $dark;
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			height: 24px;
			margin-right: 12px;
		}
		p {
			font-size: 16px;
			color: $grey-600;
		}
	}
}

@media screen and (max-width: $sm) {
	footer {
		height: 200px !important;

		.container {
			.title {
				h5 {
					font-size: 16px;
				}
			}

			.link-list {
				a {
					svg {
						height: 24px;
					}
				}
			}
		}
		.copyright {
			height: 36px;
			img {
				height: 16px;
			}
			p {
				font-size: 12px;
			}
		}
	}
}

@media screen and (max-width: $xs) {
	footer {
		height: 160px !important;

		.container {
			.title {
				h5 {
					font-size: 16px;
					margin-bottom: 20px;
				}
			}

			.link-list {
				a {
					svg {
						height: 24px;
					}
				}
			}
		}
		.copyright {
			height: 36px;
			img {
				height: 16px;
			}
			p {
				font-size: 10px;
			}
		}
	}
}
